import gql from 'graphql-tag'
import { EntityFields } from '..'
import { PersonTypeFragment } from './personType'
import { CompanyTypeFragment } from './companyType'

export const BasePersonFragment = gql`
  fragment PersonBase on persons_person {
    ${EntityFields}
    uid
    mainPhone: main_phone
    secondaryPhone: secondary_phone
    email
    webSite:web_site
  }
`

export const PersonFragment = gql`
  ${BasePersonFragment}
  ${PersonTypeFragment}
  fragment Person on persons_person {
    ...PersonBase
    firstName: first_name
    secondName: second_name
    surname
    companyName:company_name
    alias
    secondSurname: second_surname
    photo
    birthDate: birth_date
    secondaryPhone: secondary_phone
    type {
      ...PersonType
    }
  }
`

export const CompanyFragment = gql`
  ${BasePersonFragment}
  ${CompanyTypeFragment}
  fragment Company on persons_person {
    ...PersonBase
    companyName: company_name
    alias
    companyType: company_type {
      ...CompanyType
    }
  }
`
