import { Actions } from '../../interfaces'
import { BankData } from '@/entities/persons'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { BankData },
  actions: { [BankData.name]: actions },
}
