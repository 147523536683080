import axios from 'axios'

export async function sendError (errorInfo) {
  await axios.post('https://api.automotrizpetersen.cl/frontend-notification/api/v1/frontend-notification-service',
    errorInfo, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
}
