import gql from 'graphql-tag'

export const getUtilityCreditsByDay = gql`
  query aggregates($aggregate: loans_offer_bool_exp) {
    loans_offer_aggregate(where: $aggregate) {
      aggregate {
        sum {
          fee
        }
      }
    }
  }
`

export const getCreditsByDay = gql`
  query credits($aggregate: loans_evaluation_view_bool_exp) {
    loans_evaluation_view_aggregate(where: $aggregate) {
      aggregate {
        count
      }
      nodes {
        signed_date
        channel {
          id
          name
          description
          color
        }
        executive {
          person {
            id
            first_name
            surname
          }
        }
        auto {
          registration_plate
          deals {
            channel {
              id
              description
              name
              color
            }
          }
          version_year {
            version {
              model {
                name
                brand {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`

export const getCreditsTarget = gql`
  {
    settings_process(where: {table_name: {_eq: "evaluation"}}) {
      config
    }
  }
`

export const getSignedLoans = gql`
  query aggregates($model: persons_agreement_bool_exp, $aggregate: loans_evaluation_bool_exp, $order: [persons_agreement_order_by!]) {
    aggregates: persons_agreement(where: $model, order_by: $order) {
      person {
        alias
      }
      evaluations: evaluations_aggregate(where: $aggregate) {
        aggregate {
          count(distinct: true)
        }
      }
    }
  }
`

export const getLoanEvaluations = gql`
  query loanEvaluations($model: loans_evaluation_bool_exp) {
    loans_evaluation(where: $model) {
      id
      created_at
      status {
        id
        color
        status {
          id
          name
          description
        }
      }
      closing_reason {
        id
        type {
          id
          name
          description
          color
        }
      }
      financing {
        id
        sale_order {
          id
          deal {
            id
            id_closing_reason
            stock {
              id
              status {
                id
                status {
                  id
                  name
                }
              }
            }
          }
        }
      }
    }
  }
`
