import gql from 'graphql-tag'
import { FinancialAccountFragment } from '@/graphql/fragments/finance'
import { PersonAccountFragment } from '@/graphql/fragments/persons'

export const query = gql`
  ${FinancialAccountFragment}
  ${PersonAccountFragment}
  query findFinancialAccount($filter: finance_financial_account_bool_exp) {
    records: finance_financial_account(where: $filter) {
      ...FinancialAccount
      personAccount:person_account {
        ...PersonAccount
      }
    }
  }
`
export default query
