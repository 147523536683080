import { Transform, Type } from 'class-transformer'
import { Entity } from '..'

import { Appraisal, Enablement, InspectionInspectedComponent, Negotiation } from '.'
import { PersonAddress } from '../persons'
import { ClosingReason, ProcessStatus } from '../settings'
import { Employee } from '../hr'
import dayjs, { Dayjs } from 'dayjs'
import { parseToNumber } from '@/utils/general'
import { Auto, MaintenanceBackupType } from '../public'

export class Category {
  name: string;
  progress: number | null;
  score: number | null;
  order: number | null;
  validated: boolean | null;
  inspectorValidated: boolean | null;
  inspectorCost: number | null;
  supervisorCost: number | null;

  get done (): boolean {
    const { inspectorValidated } = this

    return Boolean(inspectorValidated)
  }

  get total () {
    const { inspectorCost, supervisorCost, validated, name } = this

    if ((inspectorCost === null || inspectorCost === undefined) && name !== 'Identificación') return 0

    return !validated ? inspectorCost : supervisorCost
  }
}

export class InspectionMetadata {
  @Type(() => Category)
  categories: Category[];

  totalInspectedComponents = 15;
  comment = null
  authorizedAmount = null

  get validated () {
    return this.categories?.every(category => category.validated)
  }

  get progress () {
    const { categories, totalInspectedComponents } = this
    if (!categories || !Array.isArray(categories)) return 0

    const total = categories.filter(cat => cat.done).length

    return (total / totalInspectedComponents) * 100
  }

  get done () {
    const { progress } = this

    return progress === 100
  }

  get newInspection () {
    const category = this.findCategoryByName('Identificación')
    if (!category) return true

    return Boolean(!category?.progress && !category?.done && this.progress === 0)
  }

  get totalCategoriesValidated () {
    const { categories, totalInspectedComponents } = this

    const total =
      categories?.filter(category => category?.validated)?.length || 0
    if (!total) return 0
    return (total / totalInspectedComponents) * 100
  }

  get totalAmount () {
    const { categories } = this
    return categories?.reduce((acc, category) => {
      const { supervisorCost, inspectorCost } = category
      const cost = supervisorCost || inspectorCost

      return acc + (parseInt(String(cost)) || 0)
    }, 0)
  }

  get average () {
    const { progress, categories, totalInspectedComponents } = this

    if (!progress || progress < 77) return 0

    const total = categories.reduce((acc, category) => {
      const { score } = category
      return acc + (score || 0)
    }, 0)

    return total / (totalInspectedComponents - 2)
  }

  get inspectorAllValidated () {
    const { categories } = this

    return categories.every(category => category.inspectorValidated)
  }

  get supervisorAllowValidated () {
    const { categories } = this
    const categ = ['Identificación', 'Documentación']

    const filteredCategories = categories?.filter(category => categ.includes(category.name))
    return filteredCategories?.every(category => category.validated)
  }

  get canQualify () {
    const { categories } = this

    const categ = ['Identificación', 'Documentación', 'Carrocería']

    const filteredCategories = categories?.filter(category => categ.includes(category.name))
    return filteredCategories?.every(category => category.inspectorValidated)
  }

  findCategoryByName (name: string) {
    if (!name) return null

    return (
      this.categories?.find(category => category.name === name) || {
        score: -1,
        done: false,
        progress: -1,
        validated: false,
        inspectorCost: null,
        supervisorCost: null,
        total: null,
        order: -1,
      }
    )
  }

  findCategoryPos (name: string) {
    if (!name) return null

    return this.categories?.findIndex(category => category.name === name)
  }
}

export class Inspection extends Entity {
  @Type(() => Appraisal)
  appraisal: Appraisal;

  @Type(() => ProcessStatus)
  status: ProcessStatus;

  @Type(() => Dayjs)
  @Transform(({ value }) => dayjs(value), { toClassOnly: true })
  scheduledDate: Dayjs;

  @Type(() => PersonAddress)
  address?: PersonAddress;

  @Type(() => Dayjs)
  @Transform(({ value }) => value && dayjs(value), { toClassOnly: true })
  date?: Dayjs;

  @Type(() => Employee)
  inspector: Employee;

  @Type(() => Employee)
  supervisor: Employee;

  @Type(() => Enablement)
  enablement: Enablement;

  @Type(() => ClosingReason)
  closingReason: ClosingReason;

  @Type(() => InspectionInspectedComponent)
  inspectedComponents: InspectionInspectedComponent[];

  @Type(() => Negotiation)
  negotiation: Negotiation;

  @Type(() => MaintenanceBackupType)
  backupType: MaintenanceBackupType;

  @Type(() => InspectionMetadata)
  metadata: InspectionMetadata;

  inspectorQualification?: number;
  supervisorQualification?: number;

  get customId () {
    const { date, id } = this
    if (!date) return null

    return `${date.format('YYYY')}${this.getIdWithFiveDigits(id)}`
  }

  get scheduled () {
    const { scheduledDate } = this
    return this.formatDate(scheduledDate)
  }

  get dateFormatted () {
    const { date } = this
    return date?.format('DD/MM/YYYY HH:MM')
  }

  get qualification () {
    const {
      supervisorQualification,
      supervisor,
      inspectorQualification,
      inspector,
      id,
    } = this
    return {
      id,
      supervisor: supervisor && supervisorQualification
        ? {
          qualifier: supervisor,
          qualification: supervisorQualification,
          role: 'Supervisor',
        }
        : undefined,
      inspector: inspector
        ? {
          qualifier: inspector,
          qualification: inspectorQualification,
          role: 'Inspector',
        }
        : undefined,
    }
  }

  get client () {
    const {
      appraisal: {
        deal: {
          lead: { client },
        },
      },
    } = this
    return client
  }

  get executive () {
    const {
      appraisal: {
        deal: {
          lead: { executive },
        },
      },
    } = this

    return executive
  }

  get inspectionStatus () {
    return this
  }

  get scheduledDateLocalTime () {
    const { scheduledDate } = this
    if (!scheduledDate) return null

    const local = dayjs(scheduledDate)

    const offset = dayjs().utcOffset()

    const localSchedulingDate = local.add(offset, 'minute')

    return dayjs(localSchedulingDate)
  }

  get deal () {
    const { appraisal } = this

    if (!appraisal) return undefined

    const { deal } = appraisal
    return deal
  }

  get auto (): Auto {
    const { deal } = this
    if (!deal) return undefined

    const { auto } = deal
    return auto
  }

  get autoGeneration () {
    const { auto } = this
    if (!auto) return undefined

    const { generation } = auto
    return generation
  }

  get dealMileage () {
    const { deal, appraisal } = this

    const attributes = deal.autoAttributes?.filter(attribute => {
      return attribute.slug === 'mileage' && appraisal.id === attribute.record
    })

    return parseToNumber(attributes[0]?.value)
  }

  get totalScore () {
    const { inspectedComponents } = this
    if (!inspectedComponents || !Array.isArray(inspectedComponents)) {
      return 0
    }
    return inspectedComponents.reduce((acc, component) => {
      const { componentScoreQualification } = component
      return acc + (componentScoreQualification || 0)
    }, 0)
  }

  get maintenances () {
    const { deal } = this
    if (!deal) return []

    const {
      auto: { maintenances },
    } = deal
    return maintenances?.filter(maintenance => maintenance.status.isDone)
  }

  get prepaidMaintenances () {
    const { deal } = this
    if (!deal) return []

    const {
      auto: { maintenances },
    } = deal

    return maintenances?.filter(maintenance => maintenance.status.isPrepaid)
  }

  get allMaintenances () {
    const { deal } = this
    if (!deal) return []

    const {
      auto: { maintenances },
    } = deal

    return maintenances
  }

  getCategoryScore (categoryId: number, filter: string | null = null) {
    const { inspectedComponents } = this

    if (!inspectedComponents || !Array.isArray(inspectedComponents)) {
      return 0
    }

    return inspectedComponents.reduce((acc, component) => {
      const {
        inspectionComponent: {
          component: componentCategory,
          inspectionParameters,
        },
        inspectionQualifications,
      } = component

      if (!componentCategory) {
        return acc
      }

      const {
        category: { id },
      } = componentCategory

      if (parseToNumber(id) !== parseToNumber(categoryId)) {
        return acc
      }

      const filteredInspectionParameters =
        filter !== null
          ? inspectionParameters.filter(
            p =>
              p.inspectionComponent.component &&
              p.inspectionComponent.component.slug === filter
          )
          : inspectionParameters

      const parameterIds = filteredInspectionParameters.map(p => p.id)
      const qualifiedAssessments = inspectionQualifications
        .filter(q => parameterIds.includes(q.parameter.id))
        .map(q => q?.assessment?.score)

      return (
        acc +
        (qualifiedAssessments.reduce(
          (a, b) => (isNaN(a) ? 0 : a) + (isNaN(b) ? 0 : b),
          0
        ) || 0)
      )
    }, 0)
  }

  calculateTotalProgress (categories) {
    if (
      !this.inspectedComponents ||
      !Array.isArray(this.inspectedComponents) ||
      !Array.isArray(categories)
    ) {
      return 0
    }

    const categoryQualificationMap = new Map()

    this.inspectedComponents.forEach(component => {
      const {
        inspectionComponent: { component: componentCategory },
        inspectionQualifications,
      } = component

      if (!componentCategory) {
        return
      }

      const {
        category: { id },
      } = componentCategory

      const qualificationParameterIds = this.extractQualificationParameterIds(
        inspectionQualifications
      )
      if (!qualificationParameterIds) {
        return
      }

      if (!categoryQualificationMap.has(id)) {
        categoryQualificationMap.set(id, new Set())
      }
      const existingQualifications = categoryQualificationMap.get(id)
      qualificationParameterIds.forEach(qid =>
        existingQualifications.add(qid)
      )
    })

    const qualifiedCategories = categories.reduce((acc, category) => {
      const { id, components } = category
      const parameterIds = this.extractParameterIds(components)
      if (!parameterIds) {
        return acc
      }

      const allQualificationsForCategory = Array.from(
        categoryQualificationMap.get(id) || []
      )
      if (
        this.areAllParametersQualified(
          parameterIds,
          allQualificationsForCategory
        ) &&
        !acc.includes(id)
      ) {
        acc.push(id)
      }

      return acc
    }, [])

    return qualifiedCategories.length
  }

  isCategoryComplete (category): boolean {
    const { inspectedComponents } = this

    if (
      !inspectedComponents ||
      !Array.isArray(inspectedComponents) ||
      !category
    ) {
      return false
    }

    const expectedParameterIds = new Set<number>()
    category.components.forEach(component => {
      component.inspectionComponents.forEach(inspectionComponent => {
        inspectionComponent.inspectionParameters.forEach(param => {
          expectedParameterIds.add(parseToNumber(param.id))
        })
      })
    })

    const actualInspectedParameterIds = new Set<number>()
    inspectedComponents.forEach(component => {
      const {
        inspectionComponent: { component: componentCategory },
        inspectionQualifications,
      } = component

      if (!componentCategory) {
        return
      }

      const {
        category: { id },
      } = componentCategory
      if (parseToNumber(id) === parseToNumber(category.id)) {
        inspectionQualifications.forEach(qual => {
          actualInspectedParameterIds.add(parseToNumber(qual.parameter.id))
        })
      }
    })

    return Array.from(expectedParameterIds).every(id =>
      actualInspectedParameterIds.has(id)
    )
  }

  private extractParameterIds (components) {
    if (!components || !Array.isArray(components)) {
      return null
    }
    return components.flatMap(c =>
      c.inspectionComponents.flatMap(ic =>
        ic.inspectionParameters.map(p => p.id)
      )
    )
  }

  private extractQualificationParameterIds (inspectionQualifications) {
    if (!inspectionQualifications || !Array.isArray(inspectionQualifications)) {
      return null
    }
    return inspectionQualifications.map(q => q.parameter.id)
  }

  private areAllParametersQualified (parameterIds, qualificationParameterIds) {
    if (!parameterIds || !qualificationParameterIds) {
      return false
    }
    return parameterIds.every(pid => qualificationParameterIds.includes(pid))
  }

  get amountNegotiation () {
    const { appraisal, metadata } = this

    return appraisal.price - (metadata?.authorizedAmount || metadata.totalAmount)
  }

  get inspectionHasNegotiation () {
    const { negotiation } = this

    return Boolean(negotiation?.id)
  }

  private getIdWithFiveDigits (id: number) {
    return id.toString().padStart(5, '0')
  }

  get alert () {
    const { status, closingReason } = this

    if (status.isActive) {
      return {
        icon: status.icon,
        color: 'white',
        tooltip: status.description,
        background: status.color,
      }
    }

    if (status.isClosed && (closingReason?.notQualify || closingReason?.notMatch)) {
      return {
        icon: closingReason.icon,
        color: 'white',
        tooltip: closingReason.description,
        background: closingReason.color,
      }
    }

    return undefined
  }
}
