import gql from 'graphql-tag'

import { EntityFields } from '..'
import { AccountTypeFragment } from '@/graphql/fragments/persons/accountType'
import { PersonTypeFragment } from '@/graphql/fragments/persons/personType'

export const BaseBankDataFragment = gql`
  fragment BaseBankData on persons_bank_data {
    ${EntityFields}
    accountNumber:account_number
  }
`

export const BankDataFragment = gql`
  ${BaseBankDataFragment}
  ${AccountTypeFragment}
  ${PersonTypeFragment}
  fragment BankData on persons_bank_data {
    ...BaseBankData
    accountType:account_type{
      ...AccountType
    }
    bank{
      id
      type {
        ...PersonType
      }
      alias
    }
  }
`
