import { Actions } from '../../interfaces'

import { FinancialAccount } from '@/entities/finance'

import find from './find'

export const actions: Actions = {
  find,
}

export default {
  Model: { FinancialAccount },
  actions: { [FinancialAccount.name]: actions },
}
