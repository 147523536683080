import { State as RootState } from '@/store/state'
import { ActionTree } from 'vuex'
import { Bank } from '@/store/persons/bank/state'
import { bankAccountData, getAccountTypes, getBanks } from '@/store/persons/bank/queries/info'

import { createBankData, personUpdateAccountState } from '@/store/persons/bank/mutation/bank'
import { BankDataDetails, BankDBDetails } from '@/utils/generalInterface'

const setBankData = (
  bank: BankDataDetails,
  id: number | null | undefined
): BankDBDetails => {
  const idBank = bank.bankId

  return {
    account: bank.accountNumber,
    idBank,
    // @ts-ignore
    idHolder: parseInt(id),
    accountType: bank.accountType,
  }
}

async function getBankData (apolloClient, bankId) {
  return apolloClient.query({
    query: bankAccountData,
    variables: {
      account: bankId,
    },
    fetchPolicy: 'network-only',
  })
}

function handleBankData (commit, bankData, personId) {
  const isMyBankAccount = checkBankAccount(bankData[0].persons, personId, true)
  const isMyBankAccountDisabled = bankData.find(bank => checkBankAccount(bank.persons, personId, false))
  const isAllAccountsDisabled = bankData[0].persons.every(personBank => !personBank.active)

  if (isMyBankAccount) {
    sendNotification('Número de cuenta ya esta asociado a este titular', 'error')
    commit('setAddAccountState', false)
  } else if (isMyBankAccountDisabled && isAllAccountsDisabled) {
    commit('setAddAccountState', isAllAccountsDisabled)
    commit('setAccountFound', generateAccountFoundData(isMyBankAccountDisabled, bankData[0].persons[0]))
  } else {
    handleOtherCases(commit, bankData, personId)
  }
}

function checkBankAccount (persons, personId, isActive) {
  return persons.some(personBank => personBank.id.toString() === personId && personBank.active === isActive)
}

function sendNotification (message, type) {
  window.dispatchEvent(
    new CustomEvent('notification-message', {
      detail: {
        message,
        type,
      },
    })
  )
}

function generateAccountFoundData (bankData, personAccounts) {
  return {
    accountType: bankData.accountType,
    accountNumber: bankData.accountNumber,
    idBank: bankData.idBank,
    idHolder: bankData.idHolder,
    id: bankData.id,
    personAccounts,
  }
}

function handleOtherCases (commit, bankData, personId) {
  if (bankData[0].idHolder !== parseInt(personId) && bankData[0].persons.some(person => person.active)) {
    const biPersonal = bankData[0]
    commit('setBiPersonalInfo', generateAccountFoundData(biPersonal, biPersonal.persons.find(person => person.id === biPersonal.idHolder)))
  } else {
    commit('setAccountFound', {
      ...generateAccountFoundData(bankData[0], null),
      bipersonal: true,
    })
  }
}

export const actions: ActionTree<Bank, RootState> = {
  updateAccountState: async (
    { rootState: { apolloClient } },
    bankId
  ): Promise<void> => {
    if (!apolloClient || !bankId) {
      return
    }

    await apolloClient.mutate({
      mutation: personUpdateAccountState,
      variables: {
        id: bankId,
      },
    })

    window.dispatchEvent(
      new CustomEvent('notification-message', {
        // @ts-ignore
        detail: {
          message: '¡Se ha desvinculado exitosamente!',
          type: 'success',
        },
      })
    )
  },
  updateBankDetails: async (
    { commit, rootState: { apolloClient } },
    { bank, personId, biPersonal }
  ): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { createBankData: bankData },
    } = await apolloClient.mutate({
      mutation: createBankData,
      variables: {
        account: bank.accountNumber,
        idBank: bank.idBank,
        idHolder: parseInt(personId),
        accountType: bank.accountType.name,
        bipersonal: biPersonal,
      },
    })
    commit('setAccountAdded', bankData)
  },
  createBankDetails: async (
    { commit, rootState: { apolloClient } },
    { bank, personId }
  ): Promise<void> => {
    if (!apolloClient) return
    // @ts-ignore
    const dataFormatted = setBankData(bank, personId)
    const {
      data: { createBankData: bankData },
    } = await apolloClient.mutate({
      mutation: createBankData,
      variables: { ...dataFormatted },
    })

    commit('setAccountAdded', bankData)
  },
  findBankAccount: async (
    { commit, rootState: { apolloClient } },
    { bankId, personId }
  ): Promise<void> => {
    if (!apolloClient) {
      return
    }
    const {
      data: { bankData },
    } = await getBankData(apolloClient, bankId)

    if (bankData.length && bankData[0].persons.length) {
      handleBankData(commit, bankData, personId)
    } else {
      commit('setAddAccountState', true)
    }
  },
  loadAccountTypes: async ({
    commit,
    rootState: { apolloClient },
  }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { accountTypes },
    } = await apolloClient.query({
      query: getAccountTypes,
    })

    commit('setAccountTypes', accountTypes)
  },
  loadBanks: async ({ commit, rootState: { apolloClient } }): Promise<void> => {
    if (!apolloClient) return

    const {
      data: { banks },
    } = await apolloClient.query({
      query: getBanks,
    })

    commit('setBanks', banks)
  },
}
